<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="v-card--cc">
        <v-card-title class="justify-center flex-column">
          <v-badge color="accent" bottom offset-x="24" offset-y="42">
            <v-avatar
              :color="userData.avatar ? '' : 'teal'"
              :class="userData.avatar ? '' : 'v-avatar accent--text'"
              size="120"
              class="mb-2"
            >
              <v-img
                v-if="userData.avatar"
                :src="require(`@/assets/images/avatars/${userData.avatar}`)"
                gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
              ></v-img>

              <span v-else class="text-5xl">{{ initials }}</span>

              <!-- <v-img
               gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
            >
             <span
              class="text-5xl"
            >{{ initials }}</span></v-img> -->
            </v-avatar>
          </v-badge>

          <span>{{ userData.firstName }} {{ userData.lastName }} </span>

          <v-chip
            v-if="isMyProfile"
            color="teal"
            small
            :class="`v-chip px-5 py-3 accent--text v-chip--no-color text-sm font-weight-semibold text-capitalize`"
          >
            {{
              activeRole == 'ROLE_EMPLOYEE'
                ? $t('linkHeaderRoleEmployee')
                : activeRole == 'ROLE_BAV_MANAGER'
                ? $t('linkHeaderRoleHR')
                : activeRole == 'ROLE_KEY_ACCOUNT'
                ? $t('linkHeaderRoleAM')
                : ''
            }}
          </v-chip>
          <v-chip
            v-else
            label
            small
            color="info"
            :class="`v-chip-light-bg text-sm font-weight-semibold primary--text text-capitalize`"
          >
            {{ $t('linkHeaderRoleEmployee') }}
          </v-chip>
        </v-card-title>

        <v-card-text class="mt-md-7 pl-0 pr-0 pl-md-4 pr-md-4 pb-0 pb-md-3">
          <div class="text-xl font-weight-bold pl-3 pr-3">
            {{ $t('overview') }}
          </div>

          <v-divider color="teal" class="ml-3 mr-3"></v-divider>

          <v-list class="text-base">
            <v-list-item dense class="px-0 mb-n2">
              <v-col cols="6">
                <span class="text--secondary me-2">{{ $t('formLabelCompany') }}: </span>
              </v-col>
              <v-col cols="6">
                <span class="text--secondary">{{ companyConfig.employeeFacingName }}</span>
              </v-col>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <v-col cols="6">
                <span class="text--secondary me-2">{{ $t('formLabelPersonalNumber') }}:</span>
              </v-col>
              <v-col cols="6">
                <span class="text--secondary">{{ personalDetails.personalNumber || '-' }}</span>
              </v-col>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <v-col cols="6">
                <span class="text--secondary me-2">{{ $t('bavStatus') }}: </span>
              </v-col>
              <v-col cols="6">
                <span class="text--secondary">
                  <v-chip
                    small
                    color="accent"
                    :class="`teal--text pa-3 text-sm v-chip--no-color font-weight-bold text-capitalize`"
                  >
                    {{ $t(contractStatus) }}
                  </v-chip>
                </span>
              </v-col>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <v-col cols="6">
                <span class="text--secondary me-2">{{ $t('formLabelCountry') }}:</span>
              </v-col>
              <v-col cols="6">
                <span class="text--secondary">{{ personalDetails.country || '-' }}</span>
              </v-col>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <v-col cols="6">
                <span class="text--secondary me-2">{{ $t('formLabelLanguagePreference') }}:</span>
              </v-col>
              <v-col cols="6">
                <span class="text--secondary">{{ userData.languagePreference.language.toUpperCase() }}</span>
              </v-col>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <v-col cols="6">
                <span class="text--secondary me-2">{{ $t('formLabelPhone') }}:</span>
              </v-col>
              <v-col cols="6">
                <span class="text--secondary">{{ personalDetails.phoneNumber || '-' }}</span>
              </v-col>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <v-col cols="6">
                <span class="text--secondary me-2">{{ $t('formLabelEmailPersonal') }}:</span>
              </v-col>
              <v-col cols="6">
                <span class="text--secondary">{{ personalDetails.emailPersonal || '-' }}</span>
              </v-col>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <v-col cols="6">
                <span class="text--secondary me-2">{{ $t('CONTACT_METHOD') }}:</span>
              </v-col>
              <v-col cols="6">
                <span class="text--secondary">{{ $t(personalDetails.contactMethod) || '-' }}</span>
              </v-col>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
      <ModalWindow :is-open="deleteEmployeeModal" error>
        <template #content>
          <p
            class="text-base"
            v-html="
              $t('profileDeleteUser', {
                USER: personalDetails.firstName + ' ' + personalDetails.lastName,
              })
            "
          ></p>
        </template>
        <template #actions>
          <v-btn color="primary" outlined @click="deleteEmployeeModal = false">
            {{ $t('buttonCancel') }}
          </v-btn>
          <v-btn color="primary" @click="confirmDelete">
            {{ $t('buttonConfirm') }}
          </v-btn>
        </template>
      </ModalWindow>
    </v-col>
  </v-row>
</template>

<script>
import { mdiCheck, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle, mdiClose, mdiCheckBold } from '@mdi/js';
import { avatarText, kFormatter } from '@core/utils/filter';
import { getCurrentInstance, ref, toRefs, computed, watch } from '@vue/composition-api';
import { required } from '@core/utils/validation';
import moment from 'moment';
import ModalWindow from '@/components/modal/ModalWindow.vue';
import { CONTRACT_TRANSLATION_MAP, JIRA_FIELDS, JIRA_HIDDEN_FIELD_IDS } from '@/constants';
import { deleteEmployee, getContractByEmployeeId } from '@/api/employee';

export default {
  components: { ModalWindow },
  props: ['personalDetails', 'companyConfig', 'userData', 'isMyProfile', 'sender', 'user'],
  setup(props) {
    const vm = getCurrentInstance().proxy;

    const deleteEmployeeModal = ref(false);
    const { personalDetails } = toRefs(props);
    const userData = ref({});

    const contract = getContractByEmployeeId(props.userData.id).then((response) => response);
    userData.value = {
      ...props.userData,
      status: {
        contractStatus: contract.contractStatus,
      },
    };

    const { activeRole } = vm.$store.getters;

    const contractStatus = computed(() => {
      return CONTRACT_TRANSLATION_MAP[userData.value.status.contractStatus];
    });

    const initials = computed(() => {
      if (personalDetails.value.firstName) {
        return getInitials(`${personalDetails.value.firstName} ${personalDetails.value.lastName}`);
      }
      return getInitials(personalDetails.value.email || personalDetails.value.username);
    });

    const getInitials = (str) => {
      const names = str.split(' ');
      let initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }

      return initials;
    };

    const confirmDelete = () => {
      deleteEmployee(vm.$route.params.id).then(() => {
        deleteEmployeeModal.value = false;
        vm.$router.push('/');
      });
    };
    const selectedRequestType = ref(null);
    const success = ref(false);
    const { sender, user } = toRefs(props);
    const currentLang = computed(() => vm.$store.state.app.currentLang);
    const requestTypeFieldsDictionary = {
      string: 'text',
      datetime: 'date',
      date: 'date',
      option: 'option',
      priority: 'option',
    };

    const transformValidOptions = (arr) => {
      return arr.map((i) => ({
        text: i.label,
        value: JSON.stringify({ value: i.label }),
      }));
    };

    return {
      JIRA_HIDDEN_FIELD_IDS,
      avatarText,
      kFormatter,
      initials,
      activeRole,
      contractStatus,
      deleteEmployeeModal,
      confirmDelete,
      moment,
      selectedRequestType,
      requestTypeFieldsDictionary,
      currentLang,
      transformValidOptions,
      success,

      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
        mdiClose,
        mdiCheckBold,
      },
      validators: { required },
    };
  },
};
</script>
<style lang="scss">
.v-application.skin-variant--bordered .theme--light.v-card--cc:not(.v-card .v-card) {
  border: 1px solid #f3f3f3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 1px;
}
</style>
