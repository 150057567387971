<template>
  <div>
    <v-card v-if="userConfig" class="pt-0">
      <v-card-title class="mt-2 text-center">
        <div class="text-center">Edit user</div>
      </v-card-title>
      <v-card-title>
        <div class="d-flex">
          <div>
            <span class="mb-2">{{ userConfig.email || userConfig.username }}</span>
            <div v-if="userRoles" class="d-flex mt-2">
              <v-chip
                v-if="userRoles.includes('ROLE_EMPLOYEE')"
                label
                small
                class="mr-3"
                color="info"
                :class="`v-chip-light-bg text-sm font-weight-semibold primary--text text-capitalize`"
              >
                {{ $t('linkHeaderRoleEmployee') }}
              </v-chip>
              <v-chip
                v-if="userRoles.includes('ROLE_BAV_MANAGER')"
                label
                small
                class="mr-3"
                color="info"
                :class="`v-chip-light-bg text-sm font-weight-semibold primary--text text-capitalize`"
              >
                {{ $t('linkHeaderRoleHR') }}
              </v-chip>
              <v-chip
                v-if="userRoles.includes('ROLE_KEY_ACCOUNT')"
                label
                small
                class="mr-3"
                color="info"
                :class="`v-chip-light-bg text-sm font-weight-semibold primary--text text-capitalize`"
              >
                {{ $t('linkHeaderRoleAM') }}
              </v-chip>
            </div>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-form ref="personalDataForm" class="multi-col-validation mt-6" @submit.prevent>
          <v-row class="mb-6">
            <v-col cols="12" md="12">
              <label for="radio-mr">User roles</label>
              <v-checkbox
                v-model="userRoles"
                dense
                hide-details
                :disabled="!userRoles.includes('ROLE_KEY_ACCOUNT')"
                :label="
                  userRoles.includes('ROLE_KEY_ACCOUNT')
                    ? $t('linkHeaderRoleEmployee')
                    : $t('linkHeaderRoleEmployee') + ' (DEFAULT)'
                "
                value="ROLE_EMPLOYEE"
              ></v-checkbox>
              <v-checkbox
                v-model="userRoles"
                dense
                hide-details
                :label="$t('linkHeaderRoleHR')"
                value="ROLE_BAV_MANAGER"
              ></v-checkbox>
              <v-checkbox
                v-model="userRoles"
                dense
                hide-details
                :label="$t('linkHeaderRoleAM')"
                value="ROLE_KEY_ACCOUNT"
              ></v-checkbox>
            </v-col>
          </v-row>

          <label v-if="userCompany">Root information</label>
          <v-row v-if="userCompany" class="mb-6 mt-1">
            <v-col cols="12" md="6">
              <v-text-field
                v-model="userCompany.companyName"
                label="Current Company Name"
                placeholder="Current Company Name"
                class="mt-1"
                dense
                disabled
                outlined
              ></v-text-field>
              <v-text-field
                v-model="newCompanyId"
                label="New Company ID"
                placeholder="New Company ID"
                class="mt-3"
                :disabled="loading"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="userConfig.data.companyId"
                class="mt-1"
                label="Current Company ID"
                placeholder="Current Company ID"
                dense
                disabled
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <label v-if="userRoles.includes('ROLE_BAV_MANAGER') && !userRoles.includes('ROLE_KEY_ACCOUNT')"
            >Assigned companies</label
          >
          <v-row
            v-if="userRoles.includes('ROLE_BAV_MANAGER') && !userRoles.includes('ROLE_KEY_ACCOUNT')"
            class="mb-6 mt-1"
          >
            <v-col cols="12" md="7">
              <div class="d-flex align-items-center mb-2">
                <v-text-field
                  v-model="newAssignedCompanyId"
                  class="mt-1 mr-2"
                  label="New Company ID"
                  placeholder="New Company ID"
                  dense
                  :disabled="loading"
                  outlined
                ></v-text-field>
              </div>
              <v-divider></v-divider>
              <div v-for="(c, i) in userAssignedCompanies" :key="i + ''" class="d-flex align-items-center mt-3">
                <v-text-field v-model="c.id" class="mr-2" :label="c.companyName" dense disabled outlined></v-text-field>
                <v-btn fab dark small color="error" @click="removeAssignedCompany(c.id)">
                  <v-icon>
                    {{ icons.mdiDelete }}
                  </v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-card-actions class="v-card-actions--custom">
            <v-btn color="secondary" outlined class="mt-4" type="reset" @click="$router.go(-1)">
              {{ $t('forgotPwCancel') }}
            </v-btn>
            <v-btn :loading="loading" type="submit" color="primary" class="mt-4" @click="updateUserConfiguration">
              {{ $t('formLabelSaveEdit') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, getCurrentInstance, watch } from '@vue/composition-api';
import { mdiPlus, mdiDelete } from '@mdi/js';
import { updateRootCompany } from '../../../../api/employee';
import { getCompanyById } from '../../../../api/companies';
import { getOrganizationById } from '../../../../api/organizations';
import { updateUserRoles, updateAssignedCompanies, getUser } from '../../../../api/fusion-auth';
import { ROLE_KEY_ACCOUNT, ROLE_EMPLOYEE } from '../../../../constants';

export default {
  name: 'UserConfiguration',
  setup() {
    const vm = getCurrentInstance().proxy;
    const userConfig = ref(null);
    const userRoles = ref(null);
    const userCompany = ref(null);
    const userOrg = ref(null);
    const userAssignedCompanies = ref([]);
    const newCompanyId = ref('');
    const newAssignedCompanyId = ref('');
    const loading = ref(false);
    const interval = ref(null);

    const fetchCore = () => {
      getUser(vm.$route.params.id).then((res) => {
        userConfig.value = res.data.user;
        if (userConfig.value.registrations[0] && userConfig.value.registrations[0].roles) {
          userRoles.value = userConfig.value.registrations[0].roles;
        }

        if (userConfig.value.data.companyId) {
          getCompanyById(userConfig.value.data.companyId).then((res) => {
            userCompany.value = res.data;
          });
        }

        if (userConfig.value.data.organizationId) {
          getOrganizationById(userConfig.value.data.organizationId).then((res) => {
            userOrg.value = res.data;
          });
        }

        if (userConfig.value.data.companyIdsAssigned.length) {
          userConfig.value.data.companyIdsAssigned.forEach((id) => {
            getCompanyById(id)
              .then((res) => {
                userAssignedCompanies.value.push(res.data);
              })
              .catch(() => {
                userAssignedCompanies.value.push({ companyName: `ID:${id} (Not Found)`, id });
              });
          });
        } else {
          userAssignedCompanies.value = [];
        }

        loading.value = false;
      });
    };

    const updateUserRole = () => {
      loading.value = true;
      return updateUserRoles(userConfig.value.id, userRoles.value);
    };

    const assignNewCompany = () => {
      getCompanyById(newAssignedCompanyId.value)
        .then(() => {
          let companies;

          if (userConfig.value.data.companyIdsAssigned) {
            companies = [newAssignedCompanyId.value.toString().trim(), ...userConfig.value.data.companyIdsAssigned];
          } else {
            companies = [newAssignedCompanyId.value.toString().trim()];
          }

          updateAssignedCompanies(userConfig.value.id, companies);
        })
        .catch(() => {
          newAssignedCompanyId.value = '';
          return vm.$store.commit('showMessage', {
            text: 'Company not found. Please verify provided company ID',
            color: 'error',
            timeout: '10000',
          });
        });
    };

    const removeAssignedCompany = (id) => {
      const filteredCompanies = userConfig.value.data.companyIdsAssigned.filter((item) => item !== id);
      const companies = [...filteredCompanies.map(String)];

      updateAssignedCompanies(userConfig.value.id, companies).then(() => {
        reset();
        fetchCore();
      });
    };

    const updateRootInformation = async () => {
      const newCompany = newCompanyId.value ? newCompanyId.value : userCompany.value.id;
      const promiseArr = [];

      promiseArr.push(
        getCompanyById(newCompany).catch(() => {
          newCompanyId.value = '';
          vm.$store.commit('showMessage', {
            text: 'Company not found. Please verify provided company ID',
            color: 'error',
            timeout: '10000',
          });
          throw new Error();
        }),
      );

      Promise.all(promiseArr)
        .then(() => {
          loading.value = true;
          updateRootCompany(userConfig.value.id, newCompany.trim());
        })
        .catch(() => {
          if (interval.value) clearTimeout(interval.value);
          reset();
          fetchCore();
        });
    };

    const updateUserConfiguration = () => {
      updateUserRole();
      if (newAssignedCompanyId.value) {
        assignNewCompany();
      }
      if (newCompanyId.value) {
        updateRootInformation();
      }

      interval.value = setTimeout(() => {
        reset();
        fetchCore();
        loading.value = false;
      }, 5000);
    };

    const reset = () => {
      userConfig.value = null;
      userCompany.value = null;
      userOrg.value = null;
      userRoles.value = [];
      userAssignedCompanies.value = [];
      newCompanyId.value = '';
      newAssignedCompanyId.value = '';
    };

    watch(userRoles, (v) => {
      if (!v.includes(ROLE_KEY_ACCOUNT) && !v.includes(ROLE_EMPLOYEE)) {
        userRoles.value.push(ROLE_EMPLOYEE);
      }
    });

    fetchCore();

    return {
      userConfig,
      userRoles,
      userCompany,
      newCompanyId,
      newAssignedCompanyId,
      userAssignedCompanies,
      userOrg,
      loading,
      updateUserConfiguration,
      removeAssignedCompany,

      icons: {
        mdiDelete,
        mdiPlus,
      },
    };
  },
};
</script>

<style scoped></style>
