<template>
  <v-card flat>
    <!-- <v-card-title>
      {{$t('personalProfileHeadingPersonal')}}
    </v-card-title> -->
    <v-card-text>
      <d-profile-form :personalData="personalDetails" @validation="$emit('validation', $event)" @reset="$emit('reset')">
      </d-profile-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiFilePdf } from '@mdi/js';
import DProfileForm from '@/components/profile-form/personal-data-form.vue';

export default {
  components: { DProfileForm },
  props: ['personalDetails'],
  setup() {
    return {
      icons: {
        mdiDotsVertical,
        mdiFilePdf,
      },
    };
  },
};
</script>
