<template>
  <v-form ref="taxDataForm" v-model="isTaxFormValid" class="multi-col-validation" @submit.prevent="saveChanges">
    <v-row class="align-center text-base px-md-3">
      <v-col cols="12" md="3" class="d-none d-sm-flex">
        <label for="nationality">{{ $t('formLabelNationality') }}</label>
      </v-col>
      <v-col cols="12" md="9">
        <v-select
          id="nationality"
          v-model="taxData.nationality"
          :label="$t('formLabelNationality')"
          :items="countries"
          :rules="[validators.required]"
          dense
          outlined
          hide-details
        >
          <template #message="{ message }">
            {{ $t(message) }}
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" md="3" class="d-none d-sm-flex">
        <label for="occupation">{{ $t('formLabelProfession') }}</label>
      </v-col>
      <v-col md="9" cols="12">
        <v-text-field
          id="occupation"
          v-model="taxData.occupation"
          :rules="[validators.required]"
          :label="$t('formLabelProfession')"
          :placeholder="$t('formLabelProfession')"
          dense
          outlined
        >
          <template #message="{ message }">
            {{ $t(message) }}
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="d-none d-sm-flex">
        <label for="tax-residence">{{ $t('formLabelTaxResidence') }}</label>
      </v-col>
      <v-col md="9" cols="12">
        <v-select
          id="tax-residence"
          v-model="taxData.taxResidence"
          :label="$t('formLabelTaxResidence')"
          :items="countries"
          :rules="[validators.required]"
          dense
          outlined
          hide-details
        >
          <template #message="{ message }">
            {{ $t(message) }}
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" md="3" class="d-none d-sm-flex">
        <label for="tax-class-factor">{{ $t('formLabelTaxClass') }}</label>
      </v-col>
      <v-col md="9" cols="12">
        <v-select
          id="tax-class-factor"
          v-model="taxData.taxClass"
          :items="taxClassOptions"
          :label="$t('formLabelTaxClass')"
          :placeholder="$t('formLabelTaxClass')"
          dense
          outlined
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="3" class="d-none d-sm-flex">
        <label for="child-allowance">{{ $t('formLabelChildTaxAllowance') }}</label>
      </v-col>
      <v-col md="9" cols="12">
        <v-select
          id="child-allowance"
          v-model="taxData.childAllowance"
          :label="$t('formLabelChildTaxAllowance')"
          :items="childAllowanceOptions"
          :placeholder="$t('formPlaceholderChildTaxAllowance')"
          :rules="[validators.numberRequired]"
          dense
          outlined
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" md="3" class="d-none d-sm-flex">
        <label for="church-tax">{{ $t('formLabelChurchTax') }}</label>
      </v-col>
      <v-col md="9" cols="12">
        <v-switch
          id="church-tax"
          v-model="taxData.churchTax"
          :placeholder="$t('formLabelChurchTax')"
          dense
          outlined
          hide-details
          class="mt-0 pt-0 mb-0 pb-0"
        >
          <template v-if="$vuetify.breakpoint.smAndDown" v-slot:label>
            {{ $t('formLabelChurchTax') }}
          </template></v-switch
        >
      </v-col>

      <v-col cols="12" md="3" class="d-none d-sm-flex">
        <label for="insurance-typ">{{ $t('formLabelHealthInsurance') }}</label>
      </v-col>
      <v-col md="9" cols="12">
        <v-select
          id="insurance-typ"
          v-model="taxData.healthInsuranceType"
          :items="healthInsuranceOptions"
          :label="$t('formLabelHealthInsurance')"
          :placeholder="$t('formLabelHealthInsurance')"
          dense
          outlined
          hide-details
        ></v-select>
      </v-col>

      <v-col v-if="taxData.healthInsuranceType !== HEALTH_INSURANCE_PRIVATE" cols="12" md="3" class="d-none d-sm-flex">
        <label for="insurance-provider">{{ $t('healthInsuranceProvider') }}</label>
      </v-col>
      <v-col v-if="taxData.healthInsuranceType !== HEALTH_INSURANCE_PRIVATE && !fetchExistingProvider" md="9" cols="12">
        <v-autocomplete
          id="insurance-provider"
          v-model="healthInsuranceProvider.name"
          :items="healthInsuranceProviders"
          :label="$t('healthInsuranceProvider')"
          :placeholder="$t('healthInsuranceProvider')"
          item-text="providerName"
          return-object
          dense
          outlined
          hide-details
          @change="handleHealthInsuranceProviderSelection"
        >
          <template v-slot:selection="data">
            <template>
              <span>
                <strong>{{ healthInsuranceProvider.name }}</strong>
                &mdash;{{ healthInsuranceProvider.rate }}%
              </span>
            </template>
          </template>
          <template v-slot:item="data">
            <template>
              <span v-if="data">
                <strong>{{ data.item.providerName }}</strong
                >&mdash;{{ data.item.providerContribution }}%
              </span>
            </template>
          </template>
          <template slot="no-data">
            <span class="px-2">{{ $t('myBavNoResults') }}</span>
          </template>
        </v-autocomplete>
      </v-col>

      <template v-if="taxData.healthInsuranceType === HEALTH_INSURANCE_PRIVATE">
        <v-col cols="12" md="3" class="d-none d-sm-flex">
          <label for="contribution-private">{{ $t('formLabelTotalCont') }}</label>
        </v-col>
        <v-col md="9" cols="12">
          <v-text-field
            id="contribution-private"
            v-model="taxData.healthInsurancePrivateContribution"
            dense
            :rules="[validators.required]"
            outlined
            :label="$t('formLabelTotalCont')"
          >
            <template #message="{ message }">
              {{ $t(message) }}
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="3" class="d-none d-sm-flex">
          <label for="care-insurance">{{ $t('formLabelCareInsurance') }}</label>
        </v-col>
        <v-col md="9" cols="12">
          <v-text-field
            id="care-insurance"
            v-model="taxData.careInsuranceContribution"
            dense
            outlined
            :rules="[validators.required]"
            :label="$t('formLabelCareInsurance')"
          >
            <template #message="{ message }">
              {{ $t(message) }}
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="12" md="3" class="d-none d-sm-flex">
          <label for="income-tax">{{ $t('formLabelTaxLevy') }}</label>
        </v-col>
        <v-col md="9" cols="12">
          <v-text-field
            id="income-tax"
            v-model="taxData.incomeTaxContribution"
            dense
            outlined
            :rules="[validators.required]"
            :label="$t('formLabelTaxLevy')"
          >
            <template #message="{ message }">
              {{ $t(message) }}
            </template>
          </v-text-field>
        </v-col>
      </template>

      <v-col cols="12" md="3" class="d-none d-sm-flex">
        <label for="gross-income">{{ $t('formLabelGrossWage') }}</label>
      </v-col>
      <v-col md="9" cols="12">
        <v-text-field
          id="gross-income"
          v-model="taxData.grossIncome"
          :rules="[validators.numberRequired]"
          type="number"
          dense
          outlined
          :label="$t('formLabelGrossWage')"
        >
          <template #message="{ message }">
            {{ $t(message) }}
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="d-none d-sm-flex">
        <label for="dos">{{ $t('labelEmployeeStartDate') }}</label>
      </v-col>
      <v-col md="9" cols="12">
        <v-menu
          ref="menuStartRef"
          v-model="menuStartDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              id="dos"
              v-model="taxData.employmentStartDateFormatted"
              :label="$t('labelEmployeeStartDate')"
              readonly
              outlined
              dense
              :prepend-inner-icon="icons.mdiCalendar"
              v-on="on"
            ></v-text-field>
          </template>

          <v-date-picker
            ref="picker"
            v-model="taxData.employmentStartDate"
            min="1950-01-01"
            color="primary"
            :locale="currentLang === 'en' ? 'US-en' : 'De-de'"
            @change="saveStartDate"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-card-actions class="v-card-actions--custom">
        <v-col sm="3" class="px-0">
          <v-btn block type="submit" color="primary" class="me-3 mt-4" :disabled="!allowSave">
            {{ $t('buttonSaveData') }}
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-row>
  </v-form>
</template>

<script>
import { ref, toRefs, watch, getCurrentInstance, computed, onMounted } from '@vue/composition-api';

import { required, numberRequired, percentageValidator } from '@core/utils/validation';
import { mdiCalendar } from '@mdi/js';
import moment from 'moment';
import { updateEmployeeTaxData } from '../../api/employee';
import {
  BENEFICIARY_TRANSLATION_MAP,
  BENEFICIARY_NONE,
  BENEFICIARY_PARTNER,
  BENEFICIARY_LEGAL_SUCCESSION,
  BENEFICIARY_DEATH_GRANT,
  HEALTH_INSURANCE_TRANSLATION_MAP,
  HEALTH_INSURANCE_PRIVATE,
  HEALTH_INSURANCE_STATUTORY,
  TAX_CLASS_TRANSLATION_MAP,
  TAX_CLASS_1,
  TAX_CLASS_2,
  TAX_CLASS_3,
  TAX_CLASS_4,
  TAX_CLASS_5,
  TAX_CLASS_6,
  createCountrySelection,
} from '../../constants';

export default {
  props: ['taxData', 'contract', 'healthInsuranceProviders', 'healthInsuranceProvider'],

  setup(props) {
    const vm = getCurrentInstance().proxy;
    const { taxData, contract, healthInsuranceProviders, healthInsuranceProvider } = toRefs(props);

    const currentLang = computed(() => vm.$store.state.app.currentLang);
    const allowSave = ref(false);
    const taxDataForm = ref(null);
    const isTaxFormValid = ref(false);
    const fetchExistingProvider = ref(false);
    const date = ref(null);
    const menu = ref(false);
    const menuStartDate = ref(false);
    const menuStartRef = ref(null);
    const picker = ref(null);
    const menuref = ref(null);

    const saveStartDate = (d) => {
      menuStartRef.value.save(d);
      formatStartDate();
    };
    const formatStartDate = () => {
      taxData.value.employmentStartDateFormatted = moment(taxData.value.employmentStartDate).format('DD.MM.YYYY');
    };
    const beneficiaryOptions = [
      {
        text: vm.$t(BENEFICIARY_TRANSLATION_MAP[BENEFICIARY_LEGAL_SUCCESSION]),
        value: BENEFICIARY_LEGAL_SUCCESSION,
      },
      {
        text: vm.$t(BENEFICIARY_TRANSLATION_MAP[BENEFICIARY_PARTNER]),
        value: BENEFICIARY_PARTNER,
      },
      {
        text: vm.$t(BENEFICIARY_TRANSLATION_MAP[BENEFICIARY_DEATH_GRANT]),
        value: BENEFICIARY_DEATH_GRANT,
      },
      {
        text: vm.$t(BENEFICIARY_TRANSLATION_MAP[BENEFICIARY_NONE]),
        value: BENEFICIARY_NONE,
      },
    ];

    const healthInsuranceOptions = computed(() => [
      {
        text: vm.$t(HEALTH_INSURANCE_TRANSLATION_MAP[HEALTH_INSURANCE_STATUTORY]),
        value: HEALTH_INSURANCE_STATUTORY,
      },
      {
        text: vm.$t(HEALTH_INSURANCE_TRANSLATION_MAP[HEALTH_INSURANCE_PRIVATE]),
        value: HEALTH_INSURANCE_PRIVATE,
      },
    ]);

    const childAllowanceOptions = [
      { value: 0, text: 0 },
      { value: 0.5, text: 0.5 },
      { value: 1, text: 1 },
      { value: 1.5, text: 1.5 },
      { value: 2, text: 2 },
      { value: 2.5, text: 2.5 },
      { value: 3, text: 3 },
      { value: 3.5, text: 3.5 },
      { value: 4, text: 4 },
      { value: 4.5, text: 4.5 },
      { value: 5, text: 5 },
    ];

    const taxClassOptions = [
      {
        text: vm.$t(TAX_CLASS_TRANSLATION_MAP[TAX_CLASS_1]),
        value: TAX_CLASS_1,
      },
      {
        text: vm.$t(TAX_CLASS_TRANSLATION_MAP[TAX_CLASS_2]),
        value: TAX_CLASS_2,
      },
      {
        text: vm.$t(TAX_CLASS_TRANSLATION_MAP[TAX_CLASS_3]),
        value: TAX_CLASS_3,
      },
      {
        text: vm.$t(TAX_CLASS_TRANSLATION_MAP[TAX_CLASS_4]),
        value: TAX_CLASS_4,
      },
      {
        text: vm.$t(TAX_CLASS_TRANSLATION_MAP[TAX_CLASS_5]),
        value: TAX_CLASS_5,
      },
      {
        text: vm.$t(TAX_CLASS_TRANSLATION_MAP[TAX_CLASS_6]),
        value: TAX_CLASS_6,
      },
    ];

    const countries = computed(() => createCountrySelection(currentLang.value === 'en' ? 'en' : 'de'));

    watch(
      taxData,
      () => {
        allowSave.value = true;
        vm.$emit('edit');
        vm.$emit('validation', allowSave.value && isTaxFormValid.value);
      },
      { deep: true },
    );

    watch(
      contract,
      () => {
        allowSave.value = true;
        vm.$emit('edit');
      },
      { deep: true },
    );

    const saveChanges = () => {
      const isValid = taxDataForm.value.validate();
      if (!isValid) return;

      const payload = {
        ...taxData.value,
        healthInsuranceContributionRate: Number(taxData.value.healthInsuranceContributionRate) / 100,
      };

      updateEmployeeTaxData(vm.$route.params.id, payload).then(() => {
        vm.$emit('reset');
        // updateEmployeeContract(vm.$route.params.id, contract.value).then(() => {
        // })
      });
    };

    const reset = () => {
      vm.$emit('reset');
      vm.$nextTick(() => {
        allowSave.value = false;
      });
    };

    const handleHealthInsuranceProviderSelection = (provider) => {
      if (!provider) {
        return (taxData.value.healthInsuranceId = null);
      }
      taxData.value.healthInsuranceId = provider.id;
      healthInsuranceProvider.value.name = provider.providerName;
      healthInsuranceProvider.value.rate = Number(provider.providerContribution);
    };

    watch(menu, (val) => {
      // eslint-disable-next-line no-return-assign
      val && setTimeout(() => (picker.value.activePicker = 'YEAR'));
    });

    onMounted(() => {
      if (taxData.value.employmentStartDate) {
        formatStartDate();
      } else {
        taxData.value.employmentStartDateFormatted = '';
      }
    });
    return {
      beneficiaryOptions,
      healthInsuranceOptions,
      taxClassOptions,
      allowSave,
      taxDataForm,
      isTaxFormValid,
      reset,
      saveChanges,
      handleHealthInsuranceProviderSelection,
      countries,
      childAllowanceOptions,
      HEALTH_INSURANCE_PRIVATE,
      fetchExistingProvider,
      date,
      picker,
      menuref,
      menu,
      saveStartDate,
      formatStartDate,
      menuStartDate,
      menuStartRef,
      currentLang,

      validators: {
        required,
        numberRequired,
        percentageValidator,
      },
      icons: {
        mdiCalendar,
      },
    };
  },
};
</script>
<style lang="scss">
.v-card-actions--custom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: -10px;
  .v-btn + .v-btn {
    margin-right: -20px;
  }
}
</style>
